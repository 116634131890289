enum IconType {
  OK = 'ok',
  Error = 'error',
  Loading = 'loading',
  Warning = 'warning',
  Info = 'info'
}

export enum InputType {
  Text = 'text',
  Password = 'password',
  Email = 'email',
  Number = 'number',
  Tel = 'tel',
  Url = 'url'
}
export interface IconProps {
  type: IconType;
}
