import { createReducer, Action, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { merge } from 'lodash-es';

import { APIErrors } from '@common/modules/core/services/toast/errors';
import { PrivacyMode } from '@common/modules/core/interfaces';
import { StreamingPreset } from '@common/modules/shared/interfaces';

import { UploadPages } from '../../components/upload/interfaces';
import { IIndexingSettings, SupportedAIsMap, UploadMode } from '../../interfaces';
import { IFile } from '../../interfaces';
import * as UploadActions from '../actions/upload.actions';
import { IndexingPreset } from '../../components/shared/settings';
import { loadSupportedAIs, loadSupportedAIsFailed } from '../actions';

export interface UploadState extends EntityState<IFile> {
  displayedUploadPage: UploadPages;
  supportedAIs?: SupportedAIsMap;
  isSupportedAIsLoaded?: boolean;
  isLoadSupportedAIsError?: boolean;
  indexingSettings?: IIndexingSettings;
  urlValidationLoading?: boolean;
  urlValidationError?: APIErrors;
  uploadingQuotaLimitReached?: boolean;
  uploadMode?: UploadMode; // URL or File
  userConsent: boolean;
}
function selectFileId(a: IFile): string {
  return a.id;
}

export const adapter: EntityAdapter<IFile> = createEntityAdapter<IFile>({
  selectId: selectFileId
});

export const initialUploadState: UploadState = adapter.getInitialState({
  displayedUploadPage: UploadPages.UploadSource,
  userConsent: false,
  urlValidationLoading: false,
  urlValidationError: null,
  uploadingQuotaLimitReached: false,
  supportedAIs: {} as SupportedAIsMap,
  isSupportedAIsLoaded: false,
  isLoadSupportedAIsError: false,
  indexingSettings: {
    indexingPreset: IndexingPreset.DEFAULT,
    excludeRAI: false,
    excludeAIs: [],
    peopleModelId: '',
    brandsCategories: null,
    logoGroupId: null,
    languageId: 'en-US',
    privacy: PrivacyMode.PRIVATE,
    streamingPreset: StreamingPreset.SingleBitrate
  }
});

const uploadReducer = createReducer(
  initialUploadState,
  on(UploadActions.initUploadDialogState, (state, { indexingSettings }) => {
    return merge({}, initialUploadState, {
      userConsent: state.userConsent,
      indexingSettings
    });
  }),
  on(UploadActions.addUploadFilesSuccess, (state, { files }) => {
    const newState: UploadState = {
      ...state
    };
    return adapter.addMany(files, newState);
  }),
  on(UploadActions.updateFile, (state, { fileId, file: fileProps }) => {
    return adapter.updateOne({ id: fileId, changes: fileProps }, state);
  }),
  on(UploadActions.removeFile, (state, { fileId }) => {
    return adapter.removeOne(fileId, state);
  }),
  on(UploadActions.updateUploadPage, (state, { page }) => {
    return {
      ...state,
      displayedUploadPage: page
    };
  }),
  on(UploadActions.updateUrlValidationLoading, (state, { loading }) => {
    return {
      ...state,
      urlValidationLoading: loading
    };
  }),
  on(UploadActions.getVideoInfoSuccess, state => {
    return {
      ...state,
      urlValidationLoading: false,
      urlValidationError: null
    };
  }),
  on(UploadActions.urlValidationFailed, (state, { errorType }) => {
    return {
      ...state,
      urlValidationLoading: false,
      urlValidationError: errorType
    };
  }),
  on(UploadActions.updateUrlValidationError, (state, { errorType }) => {
    return {
      ...state,
      urlValidationError: errorType
    };
  }),
  on(UploadActions.updateUploadMode, (state, { mode }) => {
    return {
      ...state,
      uploadMode: mode
    };
  }),
  on(loadSupportedAIs, state => {
    return {
      ...state,
      isSupportedAIsLoaded: false
    };
  }),
  on(loadSupportedAIsFailed, state => {
    return {
      ...state,
      supportedAIs: {},
      isLoadSupportedAIsError: true,
      isSupportedAIsLoaded: true
    };
  }),
  on(UploadActions.updateUploadSupportedAIsState, (state, { supportedAIs: ais }) => {
    const updatedSupportedAIs = {};
    ais.forEach(({ name, checked, disabled }) => {
      updatedSupportedAIs[name] = {
        ...state.supportedAIs[name],
        checked: checked,
        disabled: disabled
      };
    });
    return {
      ...state,
      supportedAIs: updatedSupportedAIs
    };
  }),
  on(UploadActions.updateUploadSupportedAIs, (state, { supportedAIs }) => {
    return {
      ...state,
      supportedAIs: supportedAIs,
      isSupportedAIsLoaded: true,
      isLoadSupportedAIsError: false
    };
  }),
  on(UploadActions.updateUploadIndexingSettings, (state, { settings }) => {
    return {
      ...state,
      indexingSettings: {
        ...state.indexingSettings,
        ...settings
      }
    };
  }),
  on(UploadActions.updateUserConsentSuccess, (state, { consent }) => {
    return {
      ...state,
      userConsent: consent
    };
  }),
  on(UploadActions.updateAccountQuotaOnUploadFailure, state => {
    return {
      ...state,
      uploadingQuotaLimitReached: true
    };
  }),
  on(UploadActions.getVideoInfoFailed, (state, { errorType }) => {
    return {
      ...state,
      urlValidationLoading: false,
      urlValidationError: errorType
    };
  })
);

export function reducer(state: UploadState | undefined, action: Action) {
  return uploadReducer(state, action);
}
